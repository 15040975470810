import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProductSearch from '@components/ProductSearch';
// import { DEFAULT_FINISH_LABEL } from '../../common';
import { DEFAULT_COLLECTION_LABEL } from '../../common';
import { viewItemListDL } from '@src/helpers/dataLayerHelper';


const TypePage = ({ location }) => {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  useEffect(() => {
    if (!hydrated) return; // Ensure it only runs after hydration
    viewItemListDL('PLP');
  }, [hydrated]);

  return <ProductSearch
    location={location}
    defaultPath="/type"
    secondaryDefaultLabel={DEFAULT_COLLECTION_LABEL}
    advancedFiltersOpen={false}
    pageName=" | Sort by Luminaire"
  />
};

TypePage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
};

export default TypePage;
